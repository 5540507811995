.popup {
  width: 100%;
  height: 100%;
  display: flex;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
  inset: 0;
  justify-content: center;
  align-items: center;
  transition: all 0.5s ease;
  opacity: 0;
  visibility: hidden;

  &_opened {
    visibility: visible;
    opacity: 1;

  }
  
  &__container {
    position: relative;
    width: 500px;
    color: var(--cl-white);
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-height: 95vh;
  }
}


@media screen and (max-width: 726px) {
  .popup {
    &__container {
      margin: 16px;
    }
  }

}
