:root {
    // z-index
    --modal-z-index: 10;

    // Цвета
    --cl-white: #fff;
    --cl-white-88: rgba(255,255,255,.88);
    --cl-white-16: rgba(255,255,255,.16);
    --cl-white-56: rgba(255,255,255,.56);
    --cl-white-08: rgba(255,255,255,.08);

    --cl-black: #202020;

    --cl-red: #F22;

    --cl-dark-blue-2:#010C28;
    --cl-dark-blue:#010B49;
    --cl-dark-gradient: linear-gradient(180deg, #010B49 0%, #021AAF 100%);
    
    --cl-gray-600:#464646;
    --cl-gray-200:#C5C5C5;
    --cl-gray-100:#F4F4F4;
}
