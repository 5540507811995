.img {
  max-height: 272px;
  width: 100%;
  display: block;
  object-fit: cover;
  border-radius: 16px;
}

.title {
  margin: 16px 0;
}

.list {
    list-style: disc outside;
    padding-left: 20px;
    display: grid;
    gap: 4px;
}
