@import "../../styles/responsive.scss";
.red {
  color: var(--cl-red);
  cursor: pointer;
  transition: opacity .15s linear;
  &:hover {
    opacity: .7;
  }
}

.grey {
  color: var(--cl-gray-600);
}

.img {
  width: 40px;
  height: 40px;
}

.list {
  display: grid;
  gap: 24px;
}

.emails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.item {
  display: flex;
  align-items: center;
  gap: 16px;
}

@include responsive(screen) {
.list {
  width: 50%;
  display: flex;
  flex-direction: column;
}
}

@include responsive(phone) {
  .list {
    width: 100%;
  }
}