@import "../../styles/responsive.scss";

.title {
  margin-bottom: 32px;
}

.about_us {
  background: var(--cl-white);
}

.wrapper {
  padding: 48px 40px;
  box-sizing: border-box;
}

.products {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  list-style: none;
}

.white {
  color: var(--cl-white);
}

.button {
  margin: 0 auto;
  max-width: max-content;
  margin-top: 32px;
}

.why {
  background-image: url("../../images/why_choose/bg.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider {
  display: none;
}

.features {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 16px;
}

.industries {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 16px;

  & + .subtitle {
    font-size: 20px;
    margin-top: 32px;
    text-align: center;
  }
}

.payments {
  background: var(--cl-white);
  & .title {
    margin-bottom: 16px;
  }

  & .options {
    display: grid;
    grid-template-columns: repeat(auto-fit, 128px);
  }

  & .subtitle {
    margin-bottom: 32px;
    color: var(--cl-gray-600);
  }
}

.contacts {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 16px;

  & .title {
    margin-top: 0;
  }

  & .info {
    padding: 32px;
    &__content {
      display: flex;
      flex-direction: column;
    }
  }

  & .block {
    border-radius: 8px;
    height: 128px;
    width: 100%;
    margin-top: 32px;
  }
}

@include responsive(screen) {
  .industries {
    grid-template-columns: repeat(3, 1fr);
  }

  .contacts {
    grid-template-columns: auto;
    gap: 0;
    &.wrapper {
      padding: 0;
    }

    & .info {
      &__content {
      gap: 32px;

        flex-direction: row-reverse;
      }
  }
  & .block {
    width: 50%;
    height: 100%;
    margin-top: 0;

  }

  
}}
@include responsive(tablet) {
  .wrapper {
    padding: 24px 16px;
  }

  .industries {
    grid-template-columns: repeat(2, 1fr);
  }

  .products {
    display: none;
  }

  .slider {
    display: block;
    padding: 0 16px;
    overflow: hidden;
    
  }

  .slider_container {
    &.wrapper {
      padding-right: 0;
      padding-left: 0;
    }
    & .title {
      padding: 0 16px;
    }
  }

  .features {
    grid-template-columns: auto;
    grid-template-areas:
      "a a d d f f"
      "b b b c c c";
    gap: 32px;
  }
}

@include responsive(phone) {
  .wrapper {
    padding: 32px 16px;
  }

  .title {
    margin-bottom: 24px;
  }

  .features {
    display: flex;
    flex-direction: column;
    gap: 24px;
  }

  .industries {
    & + .subtitle {
      margin-top: 24px;
    }
  }

  .payments {
    .subtitle {
      margin-bottom: 16px;
    }

    & .options {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .contacts {
    & .info {
      padding: 32px 16px;
      &__content {
        flex-direction: column;
        gap: 24px;
      }
  }

  & .block {
    width: 100%;
  }
  }
}
