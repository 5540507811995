@import "../../../styles/responsive.scss";

.started {
  background-image: url("../../../images/started/Pic.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 496px;
  display: flex;
  align-items: center;


}

.info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 40px;


  &__whiteText {
    display: flex;
    flex-direction: row;
    background-color: var(--cl-white);
    border-radius: 4px;
    padding: 8px;
    width: 100%;
    max-width: 393px;
    gap: 8px;

    &_txt {
      font-size: 14px;
      color: var(--cl-black);
    }
  }

  &_header {
    color: var(--cl-white);
    max-width: 664px;
    width: 100%;
  }
  &_description {
    max-width: 664px;
    width: 100%;
    color: var(--cl-white);
    line-height: 1.3;
    font-size: 20px;
    font-weight: 400;
  }
}

.button {
  margin-top: 24px;
  width: 100%;
  max-width: 199px;
}


@include responsive(tablet) {
  .started {
    background-image: url("../../../images/started/pic_2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .info {
    align-items: center;
    width: 100%;
    padding: 16px;


    &__whiteText {
      text-align: center;
      padding: 4px;
      width: 100%;
      max-width: 327px;

      &_txt {
        text-align: center;
        font-size: 12px;
      }
    }

    &_header {
      text-align: center;
    }
    &_description {
      text-align: center;
    }
  }
}

@include responsive(phone) {
  .info {
    align-items: center;

    &__whiteText {
      gap: 0;
      width: 100%;
      max-width: 317px;
 
      

      &_txt {
        margin-left: 4px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    &_header {
      text-align: center;
    }
    &_description {
      text-align: center;
    }
  }
}
