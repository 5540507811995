@import "../../styles/responsive.scss";

.conatiner {
  max-width: 1312px;
  width: 100%;
  margin: 0 auto;
  height: 100vh;
  box-sizing: border-box;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: var(--cl-black);
  line-height: 1.2;
  gap: 20px;
}

.code {
  font-size: 120px;
  margin: 0;
  margin-bottom: 30px;
}

.text {
  font-size: 46px;
  margin: 0;
}

@include responsive(phone) {
  .code {
    font-size: 80px;
  }
  .text {
    font-size: 26px;
  }
}
