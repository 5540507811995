.industry {
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  background: var(--cl-white);
  border-radius: 8px;
  padding: 20px 16px;
  padding-top: 24px;
}

.img {
  width: 40px;
  height: 40px;
  display: block;
}

.text {
  text-align: center;
}
