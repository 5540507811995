@import "../../styles/responsive.scss";

.feature {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.img {
  width: 48px;
  height: 48px;
  display: block;
}

.text {
  color: var(--cl-white);
  text-align: center;
}

@include responsive(tablet) {
  .feature {
    max-width: 184px;

    &:nth-child(1) {
      grid-area: a;
    }

    &:nth-child(2) {
      grid-area: d;
    }

    &:nth-child(3) {
      grid-area: f;
    }

    &:nth-child(4) {
      grid-area: b;
      justify-self: flex-end;
    }

    &:nth-child(5) {
      grid-area: c;
    }
  }

}

@include responsive(phone) {
  .feature {
    max-width: 100%;
    flex-direction: row;
  }

  .text {
    text-align: left;
  }

}

