@import "../../styles/responsive.scss";

.form {
  background-image: url("../../images/contacts/form.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  padding: 32px;
  flex-direction: column;
  gap: 32px;
  border-radius: 32px;
  width: 100%;
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  scroll-behavior: smooth;

  &::-webkit-scrollbar-thumb {
    height: 10px;
    background: var(--cl-white-88);
    border-radius: 50px;
    width: 3px;
  }
  &::-webkit-scrollbar {
    background-color: transparent;
    width: 3px;
  }
}

.title {
  color: var(--cl-white);
  text-align: left;
}

.errorText {
  font-size: 12px;
  margin-top: 6px;
  color: var(--cl-red);
}

.label {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &__input {
    width: 100%;
    padding: 12px;
    border: 1px solid var(--cl-white-56);
    background-color: var(--cl-white-08);
    border-radius: 8px;
    color: var(--cl-white-88);

    &::placeholder {
      color: var(--cl-white-88);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }
    cursor: pointer;
    transition: all 0.15s linear;
    &:focus {
      border: 1px solid var(--cl-white);
    }

    &:hover:not(:focus) {
      opacity: 0.7;
    }

    &.error {
      border: 1px solid var(--cl-red);
    }
  }

  &__message {
    height: 112px;
    padding: 12px;
    border: 1px solid var(--cl-white-56);
    background-color: var(--cl-white-08);
    border-radius: 8px;
    resize: none;
    color: var(--cl-white-88);
    &::placeholder {
      color: var(--cl-white-88);
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
    }

    outline: none;
    cursor: pointer;
    transition: all 0.15s linear;
    &:focus {
      border: 1px solid var(--cl-white);
    }

    &.error {
      border: 1px solid var(--cl-red);
    }

    &:hover:not(:focus) {
      opacity: 0.7;
    }

    overflow-y: auto;
    scroll-behavior: smooth;
    &::-webkit-scrollbar-thumb {
      height: 10px;
      background: var(--cl-white-88);
      border-radius: 50px;
      width: 4px;
    }
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 4px;
    }
  }
}
@include responsive(screen) {
  .form {
    border-radius: 0;
    box-sizing: border-box;

    &.popup {
      border-radius: 32px;
    }
  }

  .title {
    text-align: center;
  }

  .label,
  .btn {
    max-width: 536px;
    width: 100%;
    margin: 0 auto;
  }
}
