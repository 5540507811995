@import 'responsive.scss';

* {
  font-family: "Inter", sans-serif;
}
html {
  scroll-behavior: smooth;
}

body {
  font-size: 16px;
  line-height: 24px;
}

section {
  scroll-margin: 20px;
}


.section {
  max-width: 1408px;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
}

.bold {
  font-weight: 600;
}

h1 {
  line-height: 1.3;
  font-size: 40px;
  font-weight: bold;
}

h2 {
  line-height: 1.3;
  font-size: 32px;
  font-weight: bold;
}

h3 {
  line-height: 1.3;
  font-size: 28px;
  font-weight: bold;
}

h4 {
  line-height: 1.3;
  font-size: 24px;
  font-weight: bold;
}

h5 {
  line-height: 1.3;
  font-size: 20px;
  font-weight: bold;
}

p {
  line-height: 24px;
  font-size: 16px;
}

button {
  font-size: 16px;
  line-height: 24px;
  font-weight: bold;
}


@include responsive(tablet) { 
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
}

@include responsive(phone) {
  h1 {
    font-size: 32px;
  }
  h2 {
    font-size: 28px;
  }
  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }
  h5 {
    font-size: 16px;
  }
}

