@import "../../../styles/responsive.scss";

.aboutUs {
  background-color: var(--cl-white);
  display: flex;
  flex-direction: column;
  padding: 48px 40px;
  box-sizing: border-box;
  max-width: 1408px; //width with paddings
  margin: 0 auto;

  &_block {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
    gap: 32px;
  }

  &__description {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &_head {
      font-weight: 600;
      color: var(--cl-black);
    }
  }

  &__header {
    color: var(--cl-black);
    margin-bottom: -35px;
  }

  &_text {
    font-weight: 400;
    color: var(--cl-black);
  }

  &__image {
    width: 100%;
    max-width: 640px;
    display: block;
    object-fit: cover;
    min-height: 327px;
    border-radius: 16px;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &_item {
    display: flex;
    flex-direction: row;
    gap: 8px;
  }
}

@include responsive(fullscreen) {
  .aboutUs {
    padding: 48px 40px;
    &__image {
      width: 100%;
      min-height: 380px;
      border-radius: 16px;
    }
  }
}
@include responsive(screen) {
  .aboutUs {
    &__image {
      width: 100%;
      min-height: 447px;
      border-radius: 16px;
    }
  }
}
@include responsive(tablet) {
  .aboutUs {
    padding: 24px 16px;
    &__header {
      margin-bottom: 24px;
    }
  &_block {
     display: flex;
     flex-direction: column-reverse;
  }
    &__image {
      width: 100%;
      max-width: 568px;
      min-height: 229px;
      border-radius: 16px;
    }
  }
}
@include responsive(phone) {

}
