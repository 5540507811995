@import "../../../styles/responsive.scss";

.container {
  background: var(--cl-dark-gradient);
}

.wrapper{
  padding: 40px;
}

.footer {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  max-width: 1408px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  gap: 7px;
  
  &__line {
    color: var(--cl-white);
  }
}

.line {
  overflow: hidden;
  white-space: nowrap;
  color: var(--cl-black);
  font-size: 12px;
  font-weight: 400;
  line-height: 1;
  background: var(--cl-white);
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &__logo {
    margin-right: 6px;
    width: 16px;
    height: 16px;
  }
}

.marquee {
  display: inline-flex;
  align-items: center;
  padding-left: 100%;
  animation: marquee 30s linear infinite;
  -webkit-animation: marquee 30s infinite linear;
  -moz-animation: marquee 30s infinite linear;
}

.line:hover .marquee {
  animation-play-state: paused;
}

.blockNav {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.nav {
  margin-top: -57px;
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.5;
  padding-top: 24px;
  align-items: center;

  &__link {
    color: var(--cl-white);
    transition: opacity 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
}


.text {
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 10px;
  color: var(--cl-white);
  padding: 20px;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  max-width: 1920px;
  margin: 0 auto;
}

.copyright {
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 12px;
  color: var(--cl-white);

  &__img {
    display: block;
    width: 80px;
  }
}

.social {
  display: flex;
  gap: 8px;

  &__item {
    display: block;
    cursor: pointer;
    transition: opacity 0.15s linear;
    &:hover {
      opacity: 0.7;
    }
  }
}

.icon {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;
}

@include responsive(fullscreen) {

}

@include responsive(screen) {

  .wrapper{
    padding: 24px 16px;
  }

  .blockNav {
    margin-top: 50px;
  }

}

@include responsive(tablet) {
  
}

@include responsive(phone) {

}

@-webkit-keyframes marquee {
  0% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@-moz-keyframes marquee {
  0% {
    -moz-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  100% {
    -moz-transform: translate(-100%, 0);
    transform: translate(-100%, 0);
  }
}

@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
