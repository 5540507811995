@import '../../../styles/responsive.scss';

.header {
  background: var(--cl-dark-blue-2);
  padding: 16px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.nav {
  width: 100%;
  box-sizing: border-box;
  max-width: 1328px; //width with paddings
  box-sizing: border-box;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.routes {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  gap: 32px;
  padding: 0;
}

.link {
  text-decoration: none;
  cursor: pointer;
  transition: opacity 0.15s linear;
  margin: 0;
  font-weight: 600;
  font-size: 16px;
  color: var(--cl-white);
  &:hover {
    opacity: 0.7;
  }
}

.menu {
  &_routes {
    display: none;
  }
}

.burgerСheckbox {
  position: absolute;
  visibility: hidden;
  z-index: 30;
  display: none;
}

.burger {
  cursor: pointer;
  display: none;
  position: relative;
  border: none;
  background: transparent;
  width: 28px;
  height: 24px;
  z-index: 30;
}
.burger::before,
.burger::after {
  content: "";
  left: 0;
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  border-radius: 10px;
  background: #fff;
}
.burger::before {
  top: 0;
  box-shadow: 0 11px 0 #fff;
  transition: box-shadow 0.3s 0.15s, top 0.3s 0.15s, transform 0.3s;
}
.burger::after {
  bottom: 0;
  transition: bottom 0.3s 0.15s, transform 0.3s;
}
.burgerСheckbox:checked + .burger::before {
  top: 11px;
  transform: rotate(45deg);
  box-shadow: 0 6px 0 transparent;
  transition: box-shadow 0.15s, top 0.3s, transform 0.3s 0.15s;
}
.burgerСheckbox:checked + .burger::after {
  bottom: 11px;
  transform: rotate(-45deg);
  transition: bottom 0.3s, transform 0.3s 0.15s;
}

@include responsive(fullscreen) {
  .menu {
    &_routes {
      display: none;
    }
  }
}

@include responsive(screen) {
  .menu {
    &_routes {
      display: none;
    }
  }
}

@include responsive(tablet) {
  .burgerСheckbox {
    display: block;
  }
  .burger {
    display: block;
  }
  .routes {
    min-height: 98px;
    display: none;
  }

  .header {
    padding: 16px;
  }

  .menu {
    width: 100%;
    height: 100%;
    display: flex;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 3;
    inset: 0;
    transition: all 0.3s ease;
    opacity: 0;
    visibility: hidden;
    margin-top: 64px;

    &_opened {
      visibility: visible;
      opacity: 1;
    }
    &_link {
      color: var(--cl-white);
      font-size: 24px;
      font-weight: 700;
    }

    &_routes {
      position: absolute;
      transition: all 0.5s ease;
      background: var(--cl-dark-blue-2);
      box-sizing: border-box;
      padding: 16px 16px 32px;
      box-sizing: border-box;
      display: flex;
      opacity: 0;
      flex-direction: column;
      align-items: center;
      gap: 24px;
      width: 100%;
      height: 0px;
      max-height: 280px;
      overflow: hidden;
      transition: all 0.2s ease-in;

      &.open {
        height: 100%;
        opacity: 1;
      }
    }
  }
  .menuClick {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 1.5rem;
    height: 1.5rem;
    background: transparent;
    color: var(--cl-white);
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &_open {
      left: 20px;
    }

    &:focus {
      outline: none;
    }
  }

  .line1 {
    width: 1.5rem;
    height: 0.125rem;
    background: var(--cl-white);
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    &_active {
      transform: rotate(45deg);
    }

    &_NoActive {
      transform: rotate(0);
    }
  }

  .line2 {
    width: 1.5rem;
    height: 0.125rem;
    background: var(--cl-white);
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &_active {
      transform: translateX(20px);
      opacity: 0;
    }

    &_NoActive {
      transform: translateX(0);
      opacity: 1;
    }
  }

  .line3 {
    width: 1.5rem;
    height: 0.125rem;
    background: var(--cl-white);
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;

    &_active {
      transform: rotate(-45deg);
    }

    &_NoActive {
      transform: rotate(0);
    }
  }
}

@include responsive(phone) {
  .logo {
    width: 80px;
  }
}
